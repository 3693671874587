@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	@apply bg-black text-white selection:bg-primary-500 selection:text-white;
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}

	.btn {
		@apply px-4 py-2 rounded-md font-semibold transition-colors duration-300;
	}

	.btn-primary {
		@apply bg-primary-500 text-white hover:bg-primary-700;
	}

	.btn-secondary {
		@apply bg-secondary-500 text-white hover:bg-secondary-700;
	}

	.btn-outline {
		@apply border border-gray-300 text-gray-700 hover:bg-gray-100;
	}

	body {
		@apply font-manrope;
	}

	:is(h1, h2, h3, h4, h5, h6) {
		@apply font-manrope;
	}

	.landing-pattern {
		/* position: relative; */
		--square-size: 130px;
		background-image:
			/* horizontal lines */ linear-gradient(
				90deg,
				rgba(255, 255, 255, 0.3) 1px,
				transparent 1px
			),
			linear-gradient(180deg, rgba(255, 255, 255, 0.3) 1px, transparent 1px),
			/* radial gradient */
				radial-gradient(
					circle at top,
					rgba(0, 0, 0, 0.5) 0%,
					rgba(0, 0, 0, 0.8) 800px,
					rgba(0, 0, 0, 1) 2000px
				);
		background-size: var(--square-size) var(--square-size),
			var(--square-size) var(--square-size), 100%,
			var(--square-size) var(--square-size);
		background-position: 0 0, 0 0, 0 0;
		background-repeat: repeat, repeat, no-repeat;
		background-blend-mode: multiply;
		display: grid;
		grid-template-columns: repeat(auto-fill, var(--square-size));
		grid-template-rows: repeat(auto-fill, var(--square-size));
	}
	.square {
		position: absolute;
		width: var(--square-size);
		height: var(--square-size);
		background-image: linear-gradient(
			135deg,
			rgba(255, 255, 255, 0.15) 0%,
			transparent 100%
		);
	}

	.star,
	.star--white {
		position: absolute;
		--square-size: 130px;
		--gradient-scale-factor: 0.5;
		--star-size: calc(var(--square-size) * 1);
		&.vertical-line {
			background-image: linear-gradient(
				to bottom,
				transparent
					calc(var(--square-size) * (1 - var(--gradient-scale-factor)) / 2),
				white 50%,
				transparent
					calc(var(--square-size) * (1 + var(--gradient-scale-factor)) / 2)
			);
			position: absolute;
			left: calc(var(--square-size) * 0);
			top: calc(var(--square-size) * -0.5);
			width: 1px;
			height: var(--star-size);
			background-repeat: no-repeat;
		}
		&.horizontal-line {
			background-image: linear-gradient(
				to right,
				transparent
					calc(var(--square-size) * (1 - var(--gradient-scale-factor)) / 2),
				white 50%,
				transparent
					calc(var(--square-size) * (1 + var(--gradient-scale-factor)) / 2)
			);
			position: absolute;
			top: calc(var(--square-size) * 0);
			left: calc(var(--square-size) * -0.5);
			width: var(--star-size);
			height: 1px;
			background-repeat: no-repeat;
		}
	}

	.star--black {
		position: absolute;
		--square-size: 130px;
		--gradient-scale-factor: 0.5;
		--star-size: calc(var(--square-size) * 1);
		&.vertical-line {
			background-image: linear-gradient(
				to bottom,
				transparent
					calc(var(--square-size) * (1 - var(--gradient-scale-factor)) / 2),
				black 50%,
				transparent
					calc(var(--square-size) * (1 + var(--gradient-scale-factor)) / 2)
			);
			position: absolute;
			left: calc(var(--square-size) * 0);
			top: calc(var(--square-size) * -0.5);
			width: 1px;
			height: var(--star-size);
			background-repeat: no-repeat;
		}
		&.horizontal-line {
			background-image: linear-gradient(
				to right,
				transparent
					calc(var(--square-size) * (1 - var(--gradient-scale-factor)) / 2),
				black 50%,
				transparent
					calc(var(--square-size) * (1 + var(--gradient-scale-factor)) / 2)
			);
			position: absolute;
			top: calc(var(--square-size) * 0);
			left: calc(var(--square-size) * -0.5);
			width: var(--star-size);
			height: 1px;
			background-repeat: no-repeat;
		}
	}

	.dot-pattern {
		background-image: radial-gradient(
			rgba(255, 255, 255, 0.3) 1px,
			transparent 1px
		);
		background-size: 60px 60px;
	}

	.dot-pattern--black {
		background-image: radial-gradient(rgba(0, 0, 0, 0.9) 0, transparent 1.5px);
		background-size: 60px 60px;
	}

	.dot-pattern--white {
		background-image: radial-gradient(
			rgba(255, 255, 255, 0.3) 1px,
			transparent 1px
		);
		background-size: 60px 60px;
	}

	.shadow-highlight {
		box-shadow: 0 1px 8px 2px theme(colors.primary.400 / 70%),
			/* 0 1px 8px 2px rgba(255, 255, 255, 0.7), */ 0 5px 25px 15px
				rgba(255, 255, 255, 0.35);
	}

	.instagram-gradient {
		/* https://seekcolors.com/brand/instagram/ */
		background: linear-gradient(
			45deg,
			#405de6,
			#5b51d8,
			#833ab4,
			#c13584,
			#e1306c,
			#fd1d1d,
			#f77737,
			#fcaf45,
			#ffdc80
		);
	}
}
